import { get } from "lodash";
import { navigate } from "gatsby";
import React, { Component } from "react";
import styled from "styled-components";
import { parseURLParams } from "@upsolve/shared";
import { EVENTS } from "@upsolve/shared";

import { track } from "../components/analytics/track";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import MainFooter from "../components/Layout/Footer";
import MainHeader from "../components/Layout/Header";
import P from "../components/Type/Paragraph.p";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import { H3 } from "../components/Type/Display.h";
import { HeroText } from "../components/Layout/Hero.div";

const ThankYouHero = styled(HeroText)`
  p {
    text-align: center;
    margin-bottom: 0;
  }
`;

const contentGroup = CONTENT_GROUPS.DONATIONS_PAGE;
const templateProps = {
  pageComponentName: "DonationsPage",
  pageComponentVersion: 0,
};
const trackingPageContext = { contentGroup, ...templateProps };

export default class DonationsPage extends Component {

  componentDidMount() {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
    const donationAmount = get(parseURLParams(this.props.location.search), "amount") || 0;
    if (donationAmount == null || donationAmount <= 0) {
      navigate("/donate");
      return null;
    } else {
      this.setState({ didDonate: true });
    }
  }

  render() {
    // If we're good then continue with message
    const donationAmount = get(parseURLParams(this.props.location.search), "amount") || 0;
    const numHelped = Math.ceil(donationAmount / 9.9);
    return (
      <TopLevelLayout>
        <div className="index-container">
          <SEO
            title="Donate - Give the Gift of a Fresh Start | Upsolve"
            description="Donate to Upsolve and join us in our mission to help low-income Americans recover from hardship and start new chapters of their lives."
            image="https://upsolve.org/images/meta-probono.png"
          />
          <MainHeader trackingPageContext={trackingPageContext} />
          <main>
            <ThankYouHero align="center">
              <H3>Thank you!</H3>
              <P>
                Your{donationAmount > 0 ? ` $${Number(donationAmount).toFixed(2)} donation ` : " donation "} has been
                processed.
              </P>
              <P>
                Your donation will help {numHelped} low-income Americans escape poverty and begin new chapters to their
                lives.
              </P>
            </ThankYouHero>
          </main>
          <MainFooter />
        </div>
      </TopLevelLayout>
    );
  }
}
